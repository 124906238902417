import React from "react"
import LayoutSite from "../components/layouts/layoutSite"
import styled from "styled-components"
import mtiLogo from "../styles/images/mti_hirfelhasznalo.jpg"
import PageSeo from "../components/crumbs/pageSeo"

export default function Home() {
  const MarginTop = styled.div`margin-top: 30px;`
  const LogoWrapper = styled.div`text-align: center; display: inline-block;`
  const ImageWrapper = styled.img`display: inline-block; background: #fff; padding: 3px;`

  return (
    <>
      <PageSeo
        title="Minden Percben | Impresszum"
        description="Minden amiről érdemes tudnia hazánkban és a nagyvilágban. Mert minden perc érdekes!"
        keywords="hírek, érdekességek"
        canonical="https://www.mindenpercben.hu"
        canonicalMobile = "https://m.mindenpercben.hu/"        
      />
      <LayoutSite>
        <div className="wrap_news_block">
          <div className="container-fluid">
            <div className="block-part">
              <div className="row row-glairy">
                  <div className="col-glairy col-xs-12 col-md-8">
                      <div className="row row-glairy">
                        <div className="col-glairy col-xs-12 col-md-12">
                          <div className="content content-hu">
                          <div className="content-info">
                          <h1>Főszerkesztő, adatvédelmi tisztségviselő</h1>
                          <MarginTop className="lead">Főszerkesztő: Kun Jeromos</MarginTop>
                          <div className="lead">Adatvédelmi tisztségviselő: Kun Jeromos</div>
                          <div className="text">Bármilyen kérdése, vagy észrevétele van, kérjük, forduljon hozzánk bizalommal: <i><strong>mindenpercben kukac gmail pont com</strong></i></div>
                          <LogoWrapper><ImageWrapper src={mtiLogo} alt="MTI Hírfelhasználó"></ImageWrapper></LogoWrapper>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutSite>
    </>
  )
}